.navigation-component {
    height: 3rem; // offset fixed header

    &.tall {
        height: unset;
    }

    .recent-players {
        margin-top: 3rem;

        img {
            max-height: 2rem;
            max-width: 2rem;
        }
    }

    @media screen and (max-width: 576px) {
        height: 6rem; // offset fixed header

        &.tall {
            height: unset;
        }

        .recent-players {
            margin-top: 6rem;
        }

        .navbar {
            &>.container-fluid {

                // center items on small screens
                &>a, &>form {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}