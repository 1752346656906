html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.page-contents {
  min-height: 100%;
  position: relative;
  margin: 0;
}

main {
  padding-bottom: 3rem;
}

footer {
  bottom: 0;
  height: 3rem;
  position: absolute;
  width: 100%;
}
