.player-stat-component {
    .icon {
        display: inline-block;
        margin-top: .25rem;
        min-width: 32px;
        vertical-align: bottom;
    }

    img {
        margin-right: 0.5rem;
        max-height: 2rem;
        max-width: 2rem;

        &.info-icon {
            margin-left: 1rem;
            margin-right: 0;
            max-height: 1rem;
            max-width: 1rem;
        }
    }
}
